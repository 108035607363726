<template>
  <div
    ref="wrapper"
    class="wrapper"
  >
    <v-menu
      :return-value="date"
      :disabled="disabled || readonly"
      transition="scale-transition"
      offset-y
      :close-on-content-click="false"
      max-width="290"
      v-model="modal"
      :attach="computedAttach"
      :top="isOutOfView"
      :origin="origin || (isOutOfView ? 'bottom left' : 'top left')"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :solo="solo"
          :flat="flat"
          :label="label"
          :value="computedDate"
          :clearable="clearable"
          :hide-details="hideDetails"
          v-on="on"
          ref="text"
          :readonly="readonly"
          @focus="blur"
          @click:clear="date = null;dateChanged()"
          :required="required"
          :rules="computedRules"
          :disabled="disabled"
          :prepend-inner-icon="prependIconComputed"
        />
      </template>
      <resize-observer @resize="recomputeHeight">
        <v-card class="ma-0">
          <v-date-picker
            class="ma-0"
            :first-day-of-week="firstDayOfWeek"
            :locale="i18n.locale"
            :locale-first-day-of-year="4"
            :min="min"
            :max="max"
            :range="range"
            no-title
            scrollable
            show-week
            v-model="date"
            @change="dateChanged"
          >
            <div v-if="buttonValidation">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                {{$t('t.Cancel')}}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="saveDate"
              >
                {{$t("t.Validate")}}
              </v-btn>
            </div>
          </v-date-picker>
        </v-card>
      </resize-observer>
    </v-menu>
  </div>
</template>

<script>
import i18n from '../plugins/i18n'

export default {
  inheritAttrs: false,
  components: {
    ResizeObserver: () => import('@/components/resize-observer')
  },
  computed: {
    computedAttach () {
      return this.attach ?? this.$refs.wrapper
    },
    prependIconComputed () {
      return this.hidePrependIcon ? null : this.prependIcon || this.$icon('i.Calendar')
    },
    computedRules () {
      return this.rules?.map(r => r(this.date))
    },
    computedDate () {
      if (this.date) {
        if (Array.isArray(this.date)) {
          if (this.date.length > 1) {
            return this.formatDate(this.date[0]) + ' ~ ' + this.formatDate(this.date[1])
          }
          return this.formatDate(this.date[0])
        }
        return this.formatDate(this.date)
      }
      return ''
    },
    firstDayOfWeek () {
      return this.moment.localeData(i18n.locale).firstDayOfWeek()
    }
  },
  data () {
    return {
      i18n: i18n,
      isOutOfView: false,
      modal: false,
      date: this.value
    }
  },
  methods: {
    blur () {
      this.$refs.text.blur()
    },
    dateChanged () {
      if (!this.buttonValidation) {
        this.saveDate()
      }
    },
    openModal () {
      this.modal = true
    },
    recomputeHeight (rect) {
      if (!this.$refs.wrapper) { return }
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom)
    },
    saveDate () {
      this.modal = false
      this.$emit('input', this.date)
    }
  },
  watch: {
    value (v) {
      this.date = v
    }
  },
  props: {
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    clearable: Boolean,
    buttonValidation: Boolean,
    disabled: Boolean,
    flat: Boolean,
    hideDetails: Boolean,
    hidePrependIcon: Boolean,
    label: String,
    max: String,
    min: String,
    origin: String,
    prependIcon: String,
    range: Boolean,
    readonly: Boolean,
    required: Boolean,
    rules: Array,
    solo: Boolean,
    value: [String, Date, Array]
  }
}
</script>
